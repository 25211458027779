import 'dayjs/locale/nl-be';
import '~/styles/tailwind.css';
import 'mathlive/dist/mathlive-fonts.css';

import { createInertiaApp } from '@inertiajs/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createRoot } from 'react-dom/client';

import { sentryReactStart } from '~/lib/sentry';

import { LanguageContainer } from './components/layout/language-container';
import { ThemeProvider } from './components/theme-provider';
import { LanguageProvider } from './i18n/provider';
import { T_PageProps } from './types/T_PageProps';

// Sets up relative time & Belgian locale for dayjs

dayjs.extend(relativeTime);

// Fires up Sentry

sentryReactStart();

// Creates Inertia app

createInertiaApp<T_PageProps>({
  resolve: (name) => {
    const pages = import.meta.glob<any>('./pages/**/*.tsx', { eager: true });

    const page = pages[`./pages/${name}/index.tsx`];

    page.default.layout =
      page.default.layout || ((page) => <LanguageContainer>{page}</LanguageContainer>);

    try {
      return page;
    } catch (error) {
      console.error('Error: ', error.message);
      console.log('Page: ', page);
      console.log('Name: ', name);
    }
  },
  title: (title) => (title ? `${title} | Brightbook` : 'Brightbook'),
  setup({ el, App, props }) {
    const { organization } = props.initialPage.props;

    const savedLanguage = localStorage.getItem('language');

    console.log('HELLO?', savedLanguage, organization?.language);

    createRoot(el).render(
      <LanguageProvider context={organization.kind} lng={savedLanguage ?? organization?.language}>
        <ThemeProvider template={organization?.template}>
          <App {...props} />
        </ThemeProvider>
      </LanguageProvider>,
    );
  },
});
