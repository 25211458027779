import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export function sentryReactStart() {
  Sentry.init({
    dsn: 'https://918950a7c5324f409775556b8a6772db@o342463.ingest.sentry.io/5430575',
    allowUrls: [/^https:/],
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}
